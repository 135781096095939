@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --primary-white: #fff;
  --primary-light-grey: #f4f4f4;
  --primary-orange: #ff8c00;
  --primary-dark: #3a3a3a;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0, 0, 0, 0.7);
  --primary-green: green;
}

body {
  font-family: 'Roboto Condensed', sans-serif !important;
  overflow-x: hidden;
}
/***************NAVBAR********************/

.nav-bg {
  background-color: #3a3a3a;
  background-color: var(--primary-dark);
  z-index: 2;
}
.logo {
  width: 16.25rem;
}

/*********Menu Links**********/
.nav-link {
  /* font-weight: 500; */
  /* font-size: 0.8rem; */
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  /* margin-left: 0.75rem; */
  color: #fff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid #3a3a3a;
  border-top: 0.1875rem solid var(--primary-dark);
}
.navbar-brand {
  max-width: 17.25rem !important;
  padding: 0 !important;
  height: 6rem;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid #ff8c00;
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid #ff8c00;
  border-top: 0.1875rem solid var(--primary-orange);
}

/***************HEADER********************/
.header-wrapper {
  position: relative;
  background: url(/static/media/heroImage.3d53750f.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #ff8c00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

/************************************************************TYPED TEXT********************/

.typed-text {
  font-size: 2rem;
  color: #fff;
  color: var(--primary-white);
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/***************HEADER MAIN OFFER BUTTON********************/
.btn-main-offer {
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #fff;
  color: var(--primary-white);
  margin: 2rem;
  text-decoration: none;
}

.btn-main-offer:hover {
  background-color: #f33501;
  background-color: var(--primary-hover-red);
  color: #fff;
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}
/***************PARTICLE JS********************/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/***************ABOUT ME******************************/
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid #ff8c00;
  border: 1px solid var(--primary-orange);
}

.about-heading {
  color: #ff8c00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}
/***************SERVICES********************/
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: #f4f4f4;
  background: var(--primary-light-grey);
}

.services h1 {
  color: #3a3a3a;
  color: var(--primary-dark);
  text-transform: uppercase;
}
.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #ff8c00;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: #fff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 5px solid #ff8c00;
  border-bottom: 5px solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #fff;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: #ff8c00;
  background: var(--primary-orange);
  border-bottom: 5px solid #ff8c00;
  border-bottom: 5px solid var(--primary-orange);
}

.services .box:hover .icon {
  color: #3a3a3a;
  color: var(--primary-dark);
}
.services .box:hover .circle {
  background: #fff;
  background: var(--primary-white);
}
.services .box:hover p {
  color: #fff;
  color: var(--primary-white);
}

/************************************************************EXPERIENCE********************/
.experience {
  padding: 0.7rem;
}
.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}
.experience h1 {
  text-transform: uppercase;
  color: #ff8c00;
  color: var(--primary-orange);
}
/* This is to draw the line */
.experience-wrapper::before {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  margin-left: -0.0625;
  width: 0.125rem;
  height: 100%;
  background: #ccd1d9;
  background: var(--primary-grey);
  z-index: 1;
}
.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}
.timeline-block-right {
  float: right;
}
.timeline-block-left {
  float: left;
  direction: rtl;
}
.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #3a3a3a;
  border: 0.3125rem solid var(--primary-dark);
  background: #ff8c00;
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 1;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #ef4035;
  color: var(--primary-red);
  font-weight: 900;
}

/*--------------------------MEDIA----------------------------------------------*/
@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
}
/*************************************************************************PORTFOLIO********************/

.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding: 3rem;
}

.portfolio-wrapper h1 {
  color: #ff8c00;
  color: var(--primary-orange);
}
.portfolio-image {
  width: 15rem;
  border: 1px solid #ff8c00;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 3rem;
}
@media (min-width: 639px) {
  .portfolio-image-box {
    position: relative;
    margin: 0rem;
  }
  
}
  @media (min-width: 767px) {
    .portfolio-image-box {
      position: relative;
      margin: 1.1rem;
    }
  }

@media (min-width: 991px) {
  .portfolio-image-box {
    position: relative;
    margin: 2.5rem;
  }
}

@media (min-width: 1200px) {
  .portfolio-image-box {
    position: relative;
    margin: 1rem;
  }
}

/***********************************************OVERFLOW BOX*******************/

.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  /* change1 */
  width: 15rem;
  height: 8rem;
  /* change1 completed */
  top: 0;
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3rem;
  color: #ff8c00;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 5rem;
}

/***************POPUP BOX*****************************************************/
.proj{
  text-transform: uppercase;
  font-weight: 500;
  color: #f33501;
  color: var(--primary-hover-red);
}
.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}
.hyper-link {
  cursor: pointer;
  color: #ff8c00;
  color: var(--primary-orange);
}
.hyper-link:hover {
  color: #ef4035;
  color: var(--primary-red);
}

/***************************MEDIA*************************************/
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}
@media (max-height: 570px) {
  
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 60%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}

/***************Testimonial******************************************************************/

.testimonials {
  background-image: url(/static/media/testimonial-bg.25ff33b3.png);
  background-size: cover;
  background-color: #000000;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0rem;
  width: 100%;
}

.testimonials h1 {
  color: #ff8c00;
  color: var(--primary-orange);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: rgba(0, 0, 0, 0.7);
  background: var(--primary-transparent-black);
}

.myCarousal {
  color: #fff;
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 20.875rem;
}

.myCarousal h4 {
  color: #ff8c00;
  color: var(--primary-orange);
  font-weight: 600;
  text-transform: uppercase;
}
.myCarousal p {
  color: #ccd1d9;
  color: var(--primary-grey);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}


@media (max-width: 768px) {
  .myCarousal p {
    font-size: 0.80rem;
  }

  .myCarousal h4 {
    font-size: 1rem;
  }
  
}

/* ******************************CONTACT ME ****************************************** */

.contacts {
  background: #3a3a3a;
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: #ff8c00;
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: #f4f4f4;
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}
/* **************************** INPUTS************************************************************** */
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: #ff8c00;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}
.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: #ff8c00;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}
.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: #ff8c00;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}
input.form-control {
  margin: -0.3rem 0;
}
textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: #fff;
  color: var(--primary-white);
  background: #f33501;
  background: var(--primary-hover-red);
  text-align: left;
}
.success-message {
  font-weight: 900;
  color: green;
  color: var(--primary-green);
}

/* ---------------------------medium----------------------------------------------- */

@media (max-width: 768px) {
  .contacts {
    overflow: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 1rem;
  }
}

/* ***************************************Footer component *********************************************** */

.footer{
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a{
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-grey);
}

.footer a:hover{
  text-decoration: none;
  color:#ff8c00;
  color:var(--primary-orange);
  cursor: pointer;
}

.footer p{
  margin-bottom: 0;
}
